import React from "react"

const SIBPricingPageIntro = (props) => {
  return (
    <>
      <section className="page-header bg-paper bg-tear-white">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <h1>Learn and teach with confidence</h1>
              <p>
                Shakespeare In Bits allows you to learn and teach the most
                popular works of William Shakespeare — all in one engaging,
                comprehensive, and cost-effective online package.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBPricingPageIntro
