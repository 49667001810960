import React from "react"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"

import SIBPricingPageIntro from "../components/frontend/pricingpage/intro"
import SIBPricingPagePricing from "../components/frontend/pricingpage/pricing"

import SIBCommonTestimonials from "../components/frontend/common/testimonials"
import SIBCommonSignupCTA from "../components/frontend/common/signupcta"

const PricingPage = ({ data }) => {
  return (
    <Layout showFrontendLinks={true}>
      <Seo title="Shakespeare In Bits - Pricing" />
      <SIBPricingPageIntro/>
      <SIBPricingPagePricing/>
      <SIBCommonTestimonials/>
      <SIBCommonSignupCTA/>
    </Layout>
  )
}

export default PricingPage