import React, { useState } from "react"
import { Link } from "gatsby"

const SIBPricingPagePricing = (props) => {
  const [isShowingHelp, setIsShowingHelp] = useState(false)

  const toggleHelp = (e) => {
    e.preventDefault()
    setIsShowingHelp(!isShowingHelp)
  }

  return (
    <>
      <section className="section bg-tear-yellow">
        <div className="container">
          <div className="columns mb-5">
            <div className="column is-8 is-offset-2 has-text-centered">
              <div className="content">
                <h2 className="heading-large-alt">Simple Pricing</h2>
                <p>
                  Choose a plan that works best for you — as school
                  administrator, teacher, remote educator, or parent — and
                  increase educator effectiveness and student success, at
                  school, in the digital classroom and at home.
                </p>
              </div>
            </div>
          </div>

          <div className="columns is-desktop">
            <div className="column">
              <div className="box pricing-box pricing-box-school">
                <div className="box-top">
                  <div className="ribbon"></div>
                  <h3 className="box-title">School Account</h3>
                  <p className="price">
                    From <strong>USD $4.50 – $1.00*</strong> per student/year
                  </p>
                  <Link
                    to="/contact"
                    className="button is-blue"
                    state={{ fromPricing: true }}
                  >
                    Get a Quote
                  </Link>
                  <p className="is-small">
                    * Unit price decreases as student number increases
                  </p>
                </div>
                <div className="box-bottom">
                  <h4>Highlights</h4>
                  <ul className="checkmark-list">
                    <li>Access to all 5 plays</li>
                    <li>All the great Shakespeare In Bits features</li>
                    <li>Scene level quizzes</li>
                    <li>Sample lesson plans</li>
                    <li>Accompanying play text PDF</li>
                    <li>Online access anywhere</li>
                    <li>BYOD-friendly</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="box pricing-box pricing-box-class">
                <div className="box-top">
                  <div className="ribbon"></div>
                  <h3 className="box-title">Class Account</h3>
                  <p className="price">
                    <strong>USD $135</strong> per year
                  </p>
                  <Link
                    to="/checkout"
                    className="button is-black"
                    state={{ productSelect: "singleclass" }}
                  >
                    Buy Now
                  </Link>
                  <p className="is-small">Up to 30 students</p>
                </div>
                <div className="box-bottom">
                  <h4>Highlights</h4>
                  <ul className="checkmark-list">
                    <li>Access to all 5 plays</li>
                    <li>All the great Shakespeare In Bits features</li>
                    <li>Scene level quizzes</li>
                    <li>Sample lesson plans</li>
                    <li>Accompanying play text PDF</li>
                    <li>Online access anywhere</li>
                    <li>BYOD-friendly</li>
                  </ul>
                  <p className="is-small">Note: online purchase only</p>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="box pricing-box pricing-box-single">
                <div className="box-top">
                  <div className="ribbon"></div>
                  <h3 className="box-title">Single-User</h3>
                  <p className="price">
                    <strong>USD $9.99</strong> per year
                  </p>
                  <Link
                    to="/checkout"
                    className="button is-black"
                    state={{ productSelect: "singleuser" }}
                  >
                    Buy Now
                  </Link>
                  <p className="is-small">Single-user license</p>
                </div>
                <div className="box-bottom">
                  <h4>Highlights</h4>
                  <ul className="checkmark-list">
                    <li>Access to all 5 plays</li>
                    <li>All the great Shakespeare In Bits features</li>
                    <li>Multiple quizzes</li>
                    <li>Access anywhere</li>
                    <li>Mobile & desktop friendly</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="columns mt-5">
            <div className="column is-8 is-offset-2 has-text-centered">
              <div className="content">
                <p>
                  <a
                    href="/"
                    className="text-link"
                    onClick={(e) => toggleHelp(e)}
                    onKeyDown={(e) => toggleHelp(e)}
                  >
                    Which plan is right for me?
                  </a>
                </p>
              </div>
              <div className={`content ${isShowingHelp ? "" : "is-hidden"}`}>
                <p>
                  A <b>Single-user Account</b> is intended for an individual
                  user or household. This account gives you access to everything
                  you need to study the plays of your choice. If your school or
                  teacher has a subscription, you don't need to purchase a
                  separate Single-user Account.
                </p>
                <p>
                  A <b>Class Account</b> is intended for use by one teacher and
                  a class of up to 30 students. For higher numbers of students,
                  please use the School Account option to get a quote.
                </p>
                <p>
                  For <b>School Accounts</b>, unit pricing per student decreases
                  as total number of students increases. Interested school
                  districts should also use the School Account option to get a
                  quote.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBPricingPagePricing
